import './index.css'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer.js"
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass"

THREE.Cache.enabled = true;

//Constants  
const sizes = {
                    width: window.innerWidth,
                    height: window.innerHeight
              }
              
//Margin 
const MARGIN = 0;

//Variables for screen
let SCREEN_WIDTH = window.innerWidth;
let SCREEN_HEIGHT = window.innerHeight - MARGIN * 2;

//Canvas and HTML Elements 
const canvas = document.querySelector('canvas.flash');

const renderer = new THREE.WebGLRenderer( { antialias: true, canvas: canvas} );
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
renderer.setSize( SCREEN_WIDTH, SCREEN_HEIGHT );


//Clock for animate function 
const clock = new THREE.Clock();


//*************************Camera, Controls, Scene**************************
const camera = new THREE.PerspectiveCamera( 25, SCREEN_WIDTH / SCREEN_HEIGHT, 50, 1e7 );

const controls = new OrbitControls(camera, canvas)
controls.enableDamping = true;
controls.autoRotateSpeed = 0.7;
controls.autoRotate = false;

const scene = new THREE.Scene(); 
//*************************Camera, Controls, Scene**************************

//Texture Loader
const textureLoader = new THREE.TextureLoader();

window.addEventListener( 'resize', onWindowResize );

window.addEventListener("beforeunload", 
                        function () 
                        {
                          document.body.classList.add("animate-out");
                        }
                       );

//****************************Planetary artefacts****************************
const radius = 6371; 
const tilt = 0.4101524; 
const rotationSpeed = 0.2;
const cloudsScale = 1.005;

let geometry, meshPlanet, meshClouds; 
let dirLight;
let composer;


//Mouse & Intersection
const mouse = new THREE.Vector2(); 

//****************************Planetary artefacts****************************
function init()
{
    
    //Use FogExp2 so that it grows denser in proportion to the camera distance
    scene.fog = new THREE.FogExp2( 0x000000, 0.00000025 );

    //Add directional light for front view and moon light for rear view   
    dirLight = new THREE.DirectionalLight( 0xffffff );

    //Set the light's vector equal to a vector with the same direction
	dirLight.position.set(  -1, 0, 1 ).normalize();
	scene.add( dirLight );

    
    //Earth material   
    const materialNormalMap = new THREE.MeshPhongMaterial( 
                                                            {

                                                            specular: 0x333333, //colour
                                                            shininess: 14, //highlight shininess 

                                                            //Load textures, specular and normal maps (colour and lighting-respectively)
                                                            map: textureLoader.load( "textures/planets/earth_atmos_4096.jpg" ),
                                                            specularMap: textureLoader.load( "textures/planets/earth_specular_2048.jpg" ),
                                                            normalMap: textureLoader.load( "textures/planets/earth_normal_2048.jpg" ),

                                                            //Invert the y scale to compensate for normal map handedness.
                                                            normalScale: new THREE.Vector2( 0.85, - 0.85 ), 

                                                            } 
                                                        );
    
    //Clouds material 
    const materialClouds = new THREE.MeshLambertMaterial( 
                                                            {
                                                                map: textureLoader.load( "textures/planets/earth_clouds_1024.png" ),
                                                                transparent: true
                                                            } 
                                                        );

    
    //Define the geometry for the earth and apply tweaks       
    geometry = new THREE.SphereGeometry( radius, 100, 50 );

    //Define the meshes for the earth, cloud and moon 
    meshPlanet = new THREE.Mesh( geometry, materialNormalMap );
    meshPlanet.rotation.y = 0;
    meshPlanet.rotation.z = tilt;
    scene.add( meshPlanet );
    

    meshClouds = new THREE.Mesh( geometry, materialClouds );
    meshClouds.scale.set( cloudsScale, cloudsScale, cloudsScale );
    meshClouds.rotation.z = tilt;
    scene.add( meshClouds );

  
    // postprocessing
    const renderModel = new RenderPass( scene, camera );
    composer = new EffectComposer( renderer );
    composer.addPass( renderModel );
    
    
    //Set the initial camera position 
    camera.position.set(meshPlanet.position.x * 7, 0, radius * 12);
    camera.lookAt(meshPlanet.position);

}//End init() 





const animate = () =>
{
    const elapsedTime = clock.getElapsedTime()

    // Call tick again on the next frame
    window.requestAnimationFrame(animate)

    //Call render function upon every frame
    render();
}


function render()
{
    
    const timer = Date.now() * 0.00025;
    const delta = clock.getDelta();
    meshPlanet.rotation.y += rotationSpeed * .0021;
	meshClouds.rotation.y += 1.25 * rotationSpeed * .0028;

    if (resizeRendererToDisplaySize(renderer)) 
    {
        camera.aspect = canvas.clientWidth / canvas.clientHeight;
        camera.updateProjectionMatrix();
    }
    
    controls.update( delta );
    composer.render( delta );
}

function onWindowResize()
{
    // Update sizes
    sizes.width = window.innerWidth;
    sizes.height = window.innerHeight;

    // Update camera
    camera.aspect = sizes.width / sizes.height;
    camera.updateProjectionMatrix();

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
    composer.setSize( SCREEN_WIDTH, SCREEN_HEIGHT );
}

function resizeRendererToDisplaySize(renderer) 
{
    const width = canvas.clientWidth;
    const height = canvas.clientHeight;
    const needResize = canvas.width !== width || canvas.height !== height;
    if (needResize) 
    {
      renderer.setSize(width, height, false);
    }
    return needResize;
  }


init();
animate();



